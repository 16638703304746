import GET_ARTICLES from '../class/getArticles';

const [w, d] = [window, document];

class Blocks {
    constructor() {
        this.init();
    }

    init() {
        this.recruit('.recruit-guideline__list[data-plugin="getArticles"]');
    }

    recruit(selector) {
        const recruit_block = new GET_ARTICLES(selector);
        recruit_block.makeItem = (item, content) => `
    <a href="${item.href}" class="recruit-guideline-item">
        <figure class="recruit-guideline-item__img">
          <img src="${item.thumb}" alt="${item.title}" width="420" loading="lazy">
        </figure>
        <div class="recruit-guideline-item__body">
          <h4 class="subject">
            <span>${item.title.str_truncate(24)}</span>
            <span class="arrow"></span>
          </h4>
          <span class="desc">${item.desc}</span>
        </div>
    </a>`;
        // recruit_block.makeItem = (item) => console.log(item);
        // recruit_block.render();
        recruit_block.render().then(() => {
            // ここでcontainer内の記事の数をチェックします
            const articles = d.querySelectorAll(`${selector} a.recruit-guideline-item`);
            if (articles.length === 0)
            {
                $('.recruit__guideline').hide();
                // const container = d.querySelector(selector);
                // if (container) {
                //     container.innerHTML = "公開されている記事はありません。";
                // }
            }
        });

    }
}

const onLoadHtml = () => {
    let blocks = new Blocks();
};

export default function RECRUIT() {
    // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
    d.addEventListener('rwd002.beforeDomready', (e) => domReady(e.detail));
    $(() => Promise.all([w.load['loadHtml']]).then(onLoadHtml));
}
