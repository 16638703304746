import GET_ARTICLES from "../class/getArticles";

const [d,mq] = [document,window.matchMedia( "(max-width: 1023px)" ) ];

class Blocks
{
  constructor()
  {
    this.init();
  }

  init()
  {
    this.headline();
    this.news('.block-news__list[data-plugin="getArticles"]');
    this.works('.block-works__list[data-plugin="getArticles"]');
  }

  //headline
  headline() {
    const getArticle = new GET_ARTICLES('.block-headline__article[data-plugin="getArticles"]');
    getArticle.makeItem = (item, content) => `
      <h3 class="block-headline__title"><span>NEWS</span></h3>
      <a href="${item.href}" class="block-headline__link">
        <span class="date">${item.date}</span>
        <span class="cate">［${item.category.name}］</span>
        <h3 class="subject ${item.is_new? 'is-new':''}">${item.title.str_truncate(15)}</h3>
      </a>`;
    getArticle.render();
  }

  news(selector)
  {
    const news_block = new GET_ARTICLES(selector);
    news_block.makeItem = (item,content) => `
    <li class="block-${content}-item">
      <a href="/${content}/?mode=detail&amp;article=${item.id}" class="block-${content}-item__link">
        <div class="block-${content}-item__body">
          <div class="date-cate">
            <span class="date">${item.date}</span><span class="cate">［${item.category.name}］</span>
          </div>
          <h4 class="title ${item.is_new? 'is-new':''}">${(mq.matches)? item.title.str_truncate(24) : item.title.str_truncate(40)}</h4>
        </div>
      </a>
    </li>
    `;
    // news_block.makeItem = (item) => console.log(item);
    news_block.render();
  }
  works(selector)
  {
    const works_block = new GET_ARTICLES(selector);
    works_block.makeItem = (item,content) => `
    <li class="block-${content}__item">
        <a href="${item.href}" class="block-${content}__item-link">
          <div class="block-${content}__item-img">
            <img src="${item.thumb}" alt="${item.title}">
          </div>
          <!-- /.block-${content}__item-img -->
          <div class="block-${content}__item-body">
            ${(item.category)? `<span class="cat">${item.category.name}</span>`: ''}
            <span class="subject">${item.title}</span>
          </div>
          <!-- /.block-${content}__item-body -->
        </a>
        <!-- /.block-${content}__item-link -->
      </li>
      <!-- /.block-${content}__item -->
    `;
    // works_block.makeItem = (item) => console.log(item);
    works_block.render();
  }
}

const domReady = TPL => {
  let blocks = new Blocks;
};

// const domReady = TPL => false;

const jqInit = () => false;

export default function HOME () {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );

  $(() => jqInit());
}